<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">提醒</template>
        <el-alert
            title="团购活动列表展示商品的团购相关信息"
            type="warning"
            :closable="false">
        </el-alert>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel :span="24">
      <el-form class="button" :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="活动时间:">
          <el-date-picker
              v-model="query_time"
              size="small"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="getTimeSection"
          />
        </el-form-item>
        <el-form-item label="商品名称:">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入商品名称"
              size="small"
          />
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
          <el-button style="margin-right: 10px" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">
            添加团购
          </el-button>
        </el-form-item>
      </el-form>
      <el-radio-group style="margin-left: 20px" v-model="queryForm.type" @change="buttonTab">
        <el-radio-button type="button"
                         v-for="item in button_state"
                         :key="item.id"
                         :label="item.id"
        >
          {{ item.name }}
        </el-radio-button>
      </el-radio-group>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column align="right" label="" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image v-if="row.sku_info.imgUrl" :src="row.sku_info.imgUrl" class="commodity_pic"/>
          <el-image v-else src="#" class="commodity_pic"/>
        </template>
      </el-table-column>
      <el-table-column align="left" label="商品" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.sku_info.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="sku_info.price"
          label="商品原价">
      </el-table-column>
      <el-table-column
          align="center"
          prop="price"
          label="团购价格">
      </el-table-column>
      <el-table-column
          align="center"
          prop="quantity"
          label="起购量">
      </el-table-column>
      <el-table-column
          align="center"
          prop="sales_volume"
          label="销量">
      </el-table-column>
      <el-table-column align="center" label="活动时间" show-overflow-tooltip>
        <template #default="{ row }">
          <div>开始时间:{{ row.groupPurchase.begin_time | formatDate }}</div>
          <div>结束时间:{{ row.groupPurchase.end_time | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="state"
          label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetBulkList, apiDeleteBulkGoods} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      query_time: [],
      activeName: '',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        type: 0,
        name: '',
        begin_time: '',
        end_time: '',
      },
      button_state: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '未开始'
        },
        {
          id: 2,
          name: '进行中'
        },
        {
          id: 3,
          name: '已结束'
        },
      ],
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.queryForm.begin_time = event[0]
        this.queryForm.end_time = event[1]
      } else {
        this.queryForm.begin_time = ''
        this.queryForm.end_time = ''
      }
    },
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.queryForm.type = 1
      } else if (num == 2) {
        this.queryForm.type = 2
      } else if (num == 3) {
        this.queryForm.type = 3
      } else {
        this.queryForm.type = 0
      }
      this.fetchData()
    },
    handleEdit(row) {
      if (row) {
        this.$router.push({
          path: 'groupBuying/revise',
          query: {
            id: row.group_purchase_id,
          },
        })
      } else {
        this.$router.push({
          path: 'groupBuying/revise',
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要操作当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteBulkGoods({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetBulkList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '未开始'
          : row.state == '1' ? '进行中'
              : row.state == '2' ? '已结束'
                  : '暂无'
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}

.button {
  margin-top: 20px;
}
</style>
